<template>
  <LogisticsServicesPage />
</template>

<script>
import LogisticsServicesPage from "../components/pages/LogisticsServicesPage.vue";

export default {
  components: {
    LogisticsServicesPage,
  },
};
</script>
