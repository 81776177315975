<template>
  <ContactsPage />
</template>

<script>
import ContactsPage from "../components/pages/ContactsPage.vue";

export default {
  components: {
    ContactsPage,
  },
};
</script>
