<template>
  <ProductsPage />
</template>

<script>
import ProductsPage from "../components/pages/ProductsPage.vue";

export default {
  components: {
    ProductsPage,
  },
};
</script>
