<template>
  <div>
    <footer class="footer"></footer>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.footer {
}
</style>
