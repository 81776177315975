export default {
  "nav": {
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import / Export"])},
    "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supply chain optimization"])},
    "trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade promotion optimization"])},
    "logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value-added logistics services"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])}
  },
  "home_page": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YOUR TRADING PARTNER"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We assist you in trading your products in Azerbaijan. We also have full-cycle manufacturing facilities in China."])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "our_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our services"])},
    "services": {
      "import": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMPORT / EXPORT"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many enterprises have already benefited from NAKS TRADING's assistance in importing and exporting their goods around the world."])}
      },
      "supply": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUPPLY CHAIN OPTIMIZATION"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS TRADING has extensive experience with procurement processes and developing tailored business solutions for specific needs."])}
      },
      "trade": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRADE PROMOTION OPTIMIZATION"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS TRADING splits the expense of exposition stands and promoters, presenters, marketers, and designers."])}
      },
      "logistics": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALUE-ADDED LOGISTICS SERVICES"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about our logistics outsourcing, warehouse management, and dropshipping services."])}
      }
    },
    "our_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our products"])},
    "products": {
      "consumer_electronics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumer electronics"])},
      "alternative_energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automotive Parts"])},
      "health_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical products and supplies"])},
      "industrial_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrial equipment"])},
      "souvenir_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenir products"])}
    },
    "more": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEED TO KNOW MORE ABOUT THE SERVICES AND PRODUCTS WE OFFER?"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let us provide you extra information on them."])}
    }
  },
  "import_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMPORT / EXPORT"])},
    "global_concern": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Concern"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies use global suppliers as partners to reduce their supply costs, but unplanned charges, particularly those related to imports, can substantially harm a company's finances. According to studies, the difference between the predicted and actual total cost of goods, transportation, tariffs, and taxes ranges from 2 to 10%. In extreme circumstances, the disparity can increase by more than 10%. It is therefore essential to understand how to cut import and export costs."])}
    },
    "your_partner_for_complex_solutions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Partner for Complex Solutions"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS Trading consists of a team of highly experienced Project Forwarders who are involved on a daily basis with the transport of oversized and heavy breakbulk cargo. We do this worldwide via sea freight, air freight, by land, river or by train or a combination of these modalities. We provide our services to renowned global customers from, among others, the oil and gas, dredging, petrochemical and mining industries. Our experienced project team can ship and handle your oversized or heavy cargo by boat, airplane, road, river or rail. They ensure your complex project movements will be scheduled and monitored throughout."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We manage complex logistic operations with the greatest care and precision. The requirements we set for our transport are very high. We are specialists in the field of international logistics and offer customized solutions for your complex project cargoes."])}
    },
    "why_us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why Us"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our aim is to provide localized, logistical niche solutions to the Global Market-place, serviced by highly experienced, motivated staff that care for the clients needs and interests. We are committed in providing our customers with the best quality of service. Our employees are facilitated and supported by a strong and experienced management. This is complemented by owners who provide us with a unique entrepreneurial vision and financial support. NAKS TRADING had already assisted several enterprises in effectively importing and exporting their products in Azerbaijan and China. Managers providing high quality, reliable and personalized services in the field of logistics and shipping."])}
    }
  },
  "supply_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUPPLY CHAIN OPTIMIZATION"])},
    "1_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When it comes to purchasing or procurement, where does supply chain optimization start?"])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a whopping 79% of surveyed companies say that using supply chain optimization techniques improves revenue significantly;"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supply chain optimization is the process of adjusting your supply chain to operate at peak performance;"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["use a multichannel approach to build a responsive supply chain;"])}
      },
      "paragraphs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supply chains are the lifeline of your eCommerce business. Whether you’re a manufacturer, distributor, or retailer, you’re part of an intricate web of logistics."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supply chain optimization is the process of refining your supply chain to operate at its best. This means finding ways to better align your operations, inventory, purchasing, and distribution."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If done well, optimizing your supply chain can create a competitive advantage for your business."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your company has a purchasing process rather than a procurement process, it reflects the relative importance of procurement in the organization, which has an impact on employees, potential hires, suppliers, and the industry's impression of your procurement maturity level."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchasing is the process of getting what you want when you need it. Procurement is all about forming long-term, mutually beneficial relationships with partners so that the entire supply chain can perform at its best; it's all about maximizing the company's worth."])}
      }
    },
    "2_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS TRADING supply chain optimization solution"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can set up your procurement process from the ground up, tailored to your specific business needs and skills. This procedure will pave the path for long-term objectives and profit, as well as optimize your present supply chain."])}
    },
    "3_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why Us?"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS TRADING has extensive procurement experience as well as the ability to provide bespoke business solutions for non-standard demands."])},
      "list": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR FULL PROCUREMENT SERVICE INCLUDES:"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration with the world's top e-commerce platforms."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer a full procurement service, along with complete management and handling of your stock to ultimately fulfil your needs. Our procurement channels ensure the long-term availability of necessary components, based on annual requirements, target projects or even as a last-time purchase."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiming to enable low-cost manufacturing, we are committed to purchasing components at the lowest possible price. Our procurement service will check every relevant component and supplier option to obtain a well-elaborated BOM. With the support of our global supply partners and well-established, reputable distributors, including our offshore partnerships, we can purchase your parts to help reduce costs."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service for reverse logistics. NAKS TRADING is prepared to give our customers with the option of returning defective products through a global network of warehouses. We provide a simple return policy and prompt replacement."])}
      }
    },
    "benefits": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS TRADING benefits for procurement services:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional quality control of production by QC agents of Chinese manufacturers"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OEM/ODM assistance with manufacturing in China"])}
    }
  },
  "trade_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRADE PROMOTION OPTIMIZATION"])},
    "1_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participating in exhibitions helps you to encounter a large number of valued potential clients in one location at a specified time. You will establish the groundwork for extending your network of contacts and sales by selecting the suitable exhibition for the product you are showing and carefully organizing everything you do."])},
      "paragraphs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a company is visible on a big platform, participation in the largest shows helps to boost its reputation. Exhibitions are an excellent way to gain immediate feedback on a product. An exhibition is a terrific place to check it out if you're not sure what characteristics the original product will have or if you haven't mass-produced it yet and want to see if potential purchasers will appreciate it."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can meet sales representatives, founders, investors, and speakers at exhibitions with whom you can collaborate."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can observe competitors at an exhibition to see how they position their brands, what colors they use to design their exhibits, how their reps appear, and how they entice potential buyers."])}
      }
    },
    "2_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Challenge"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing for an exhibition takes a lot of time and effort. If the exhibition is held in another country, you will need to arrange for visas, find a place to stay, select staff who will attend, prepare a presentation, speech, stand design, employee attire, business cards, brochures, and booklets. When you start doing all of this, you question if it's really essential."])},
      "paragraphs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participating in a variety of events stands to reason. Buyers, sellers, and investors want to interact, receive information, make judgments, and conclude agreements in today's digital world. The best ways to do this are through exhibitions and events."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is something that visitors to exhibitions are always in the mood for. They attend a certain event with the intent of gaining expertise and reviewing ideas. Investors can come to look for investment opportunities. As a result, exhibitions are regarded as tremendously important events. Managers spend a lot of time developing contact qualifications during the usual sales cycle. These stages are omitted at exhibitions, though, because spectators are interested. This manner, you can be certain that you have captured your audience's attention."])}
      }
    },
    "3_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many businesses still cannot afford to participate in an expo or other type of event, owing to a lack of funds. They must pay not only the entrance fees, but also all associated expenditures such as travel, visas, lodging, stand design, printing of all handouts, and the wages of managers or corporate representatives.NAKS TRADING decided to provide special conditions to assist diverse enterprises and start-ups in attending trade shows. We promise to split the cost of exposition stands and to provide promoters, speakers, marketers, and designers to help with the creation of promotional materials. At the same time, NAKS TRADING gives comprehensive participation metrics and advice."])}
    }
  },
  "logistics_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALUE-ADDED LOGISTICS SERVICES"])},
    "1_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization of distribution centers"])},
      "paragraphs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finding the capital investment required to develop an internal logistics organisation and infrastructure is often a challenge. It may be more sensible to manage more of this function as a variable cost."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mastering the growing complexity of logistics operations and the associated technology may not necessarily be the mission of a manufacturer or retail network alone. It’s time to look at working with a logistics specialist."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In warehousing and distribution, value-added services go further than normal operations and can set you apart from your competition. Among these types of activities are cross-docking, transloading, palletizing, kitting, and return processing."])}
      }
    },
    "2_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics benefits with NAKS TRADING"])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decreasing the costs of transporting and selling products to end customers."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ability to build a flexible raw material portfolio, especially those for non-standard orders or delivery terms (piece supplies, organization of just-in-time deliveries)."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrastructure for transportation and warehousing is leased."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increasing control over product and goods logistics, warehousing, and clearing operations."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipping. We can use the social media and online stores to market things from a certain vendor or set of merchants on our own behalf."])}
      }
    },
    "3_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why NAKS TRADING"])},
      "paragraphs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We deliver manufacturing components from the production point to the client's location, taking care of all paperwork, transportation, and customs clearance."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS TRADING handles all aspects of packing, labeling, storage, and transportation of raw materials and goods, allowing business management to concentrate on the product."])}
      }
    }
  },
  "products_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRODUCTS"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get more"])},
    "links": {
      "consumer_electronics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumer electronics"])},
      "alternative_energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automotive Parts"])},
      "health_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical products and supplies"])},
      "industrial_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrial equipment"])},
      "souvenir_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenir products"])}
    },
    "consumer_electronics": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumer electronics"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are focusing on best possible service and the competitive pricing to fulfil the needs of demanding market. What we could offer:"])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data storage (SSD & HDD)"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic and mining equipment (graphic cards for PC)"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central Processing Units & ICs"])}
      }
    },
    "alternative_energy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automotive Parts"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are a well-established export-oriented company that has been operating in the original car parts business for over 10 years."])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks to our experience, we can supply our customers a large variety of original spare parts for mechanics, bodywork, and accessories at highly competitive prices."])}
    },
    "health_products": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical products and supplies"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our medical products bridge the gap between the constant need for quality and the increasing demand for savings. Our brand portfolio is a comprehensive offering of clinician-preference, cost-efficient products, and physician-preferred items with low clinical differentiation, helping providers improve the bottom line while delivering high-quality care. Because we work from the acute channel to the home, we’re uniquely positioned to stay ahead, finding product efficiencies that move care forward."])}
    },
    "industrial_equipment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrial equipment"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We serve a unique market consisting of many Chemical and Refining Complexes, Industrial and Commercial Contractors, Municipalities, Utility and Power Industry, Water and Wastewater, Food and Pharmaceutical Manufacturers, Pulp and Paper Industries, and the associated Engineering Companies with Electrical, Instrumentation, Material Handling Products, Combustion and HVAC Products."])}
    },
    "souvenir_products": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenir products"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer a wide selection of novelties, toys, games, jewelry, sunglasses, inflatables, glow items, costume accessories and party products. We also offer a extensive line of custom print souvenirs and promotional products:"])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized pens"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized calendars"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized flash drives"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stands for stores and gas stations"])}
      }
    }
  },
  "contacts_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACTS"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feel free to reach out to us if you have any questions"])},
    "form": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*By clicking the button, you agree to the user agreement and"])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
      "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "placeholders": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
        "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an options"])},
        "options": {
          "consumer_electronics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumer electronics"])},
          "alternative_energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative energy"])},
          "health_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health & Wellbeing products"])},
          "industrial_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrial equipment"])},
          "souvenir_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenir products"])}
        },
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])}
      }
    }
  },
  "privacy_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRIVACY POLICY"])},
    "paragraphs": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Naks-trading.biz, accessible from Naks-trading.biz, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Naks-trading.biz and how we use it."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Naks-trading.biz."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This policy is not applicable to any information collected offline or via channels other than this website."])}
    },
    "1_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSENT"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By using our website, you hereby consent to our Privacy Policy and agree to its terms."])}
    },
    "2_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMATION WE COLLECT"])},
      "paragraphs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number."])}
      }
    },
    "3_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOW WE USE YOUR INFORMATION"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use the information we collect in various ways, including to:"])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide, operate, and maintain our website"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improve, personalize, and expand our website"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understand and analyze how you use our website"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Develop new products, services, features, and functionality"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send you emails"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find and prevent fraud"])}
      }
    },
    "4_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOG FILES"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naks-trading.biz follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information."])}
    },
    "5_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COOKIES AND WEB BEACONS"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like any other website, Naks-trading.biz uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information."])}
    },
    "6_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADVERTISING PARTNERS PRIVACY POLICIES"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may consult this list to find the Privacy Policy for each of the advertising partners of Naks-trading.biz.Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on wtrading.org, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit. Note that Naks-trading.biz has no access to or control over these cookies that are used by third-party advertisers."])}
    },
    "7_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THIRD PARTY PRIVACY POLICIES"])},
      "paragraphs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naks-trading.biz Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to optout of certain options."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites."])}
      }
    },
    "8_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCPA PRIVACY RIGHTS (DO NOT SELL MY PERSONAL INFORMATION)"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under the CCPA, among other rights, California consumers have the right to:"])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request that a business delete any personal data about the consumer that a business has collected."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request that a business that sells a consumer's personal data, not sell the consumer's personal data."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us."])}
      }
    },
    "9_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR DATA PROTECTION RIGHTS"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:"])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to erasure – You have the right to request that we erase your personal data, under certain conditions."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us."])}
      }
    }
  },
  "footer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find out more about the service"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS TRADING will provide you with the comprehensive service you require."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a free consultation"])}
  }
}