<template>
  <SupplyChainPage />
</template>

<script>
import SupplyChainPage from "../components/pages/SupplyChainPage.vue";

export default {
  components: {
    SupplyChainPage,
  },
};
</script>
