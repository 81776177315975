export default {
  "nav": {
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məhsullar üzrə"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xidmətlər"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İdxal / İxrac"])},
    "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Təchi̇zat zənci̇ri̇ni̇n opti̇mallaşdirilmasi"])},
    "trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti̇carət təşvi̇qi̇ni̇n opti̇mallaşdirilmasi"])},
    "logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əlavə dəyər təmi̇n edən logi̇sti̇ka xi̇dmətləri̇"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktları"])}
  },
  "home_page": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sizin ticarətdə Tərəfdaşınız"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz sizə məhsullarınızın Azərbaycanda ticarətini həyata keçirməkdə köməklik edirik. Çində tam dövrəli istehsal müəssisələrimiz də var."])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizimlə əlaqə saxlayın"])},
    "our_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xi̇dmətləri̇mi̇z"])},
    "services": {
      "import": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İDXAL / İXRAC"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artıq bir çox müəssisələr öz mallarının dünya üzrə idxal və ixracında NAKS TRADING-in köməyindən yararlanıblar."])}
      },
      "supply": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TƏCHİZAT ZƏNCİRİNİN OPTİMALLAŞDIRILMASI"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS TRADING, satınalma prosesləri və xüsusi ehtiyaclar üçün uyğunlaşdırılmış biznes həllərinin hazırlanması ilə bağlı geniş təcrübəyə malikdir."])}
      },
      "trade": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TİCARƏT TƏŞVİQİNİN OPTİMALLAŞDIRILMASI"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS TRADING ekspozisiya stendləri və promouterlər, aparıcılar, marketoloqlar və dizaynerlərin xərclərini bölür."])}
      },
      "logistics": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ƏLAVƏ DƏYƏR DAŞIYAN LOGİSTİKA XIDMƏTLƏRİ"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistika autsorsinqi, anbar idarəçiliyi və dropshipping (“birbaşa çatdırılma” ) xidmətlərimiz haqqında ətraflı məlumat əldə edin."])}
      }
    },
    "our_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məhsullarimiz"])},
    "products": {
      "consumer_electronics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məişət elektronikası"])},
      "alternative_energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avtomobil hissələri"])},
      "health_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sağlamlıq və Rifah Məhsulları"])},
      "industrial_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sənaye avadanlıqları"])},
      "souvenir_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suvenir məhsulları"])}
    },
    "more": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizim təklif etdiyimiz xidmətlər və məhsullar haqqında daha çox bilmək lazımdır?"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onlarla bağlı sizə əlavə məlumat verək."])}
    }
  },
  "import_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İDXAL / İXRAC"])},
    "global_concern": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qlobal narahatlıq"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirkətlər təchizat xərclərini azaltmaq üçün qlobal təchizatçılardan tərəfdaş kimi istifadə edirlər, lakin planlaşdırılmamış ödənişlər, xüsusən də idxalla bağlı xərclər şirkətin maliyyəsinə əhəmiyyətli dərəcədə zərər verə bilər. Araşdırmalara görə, malların, nəqliyyatın, tariflərin və vergilərin proqnozlaşdırılan və faktiki ümumi dəyəri arasında fərq 2-10% arasında dəyişir. Ekstremal şəraitdə fərq 10%-dən çox arta bilər. Buna görə idxal və ixrac xərclərini necə azaltmağı başa düşmək vacibdir."])}
    },
    "your_partner_for_complex_solutions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompleks Həllər üzrə Tərəfdaşınız"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS Trading gündəlik olaraq iri ölçülü və ağır yüklərin daşınması ilə məşğul olan yüksək təcrübəli Layihə Ekspeditorlarından ibarət komandadan ibarətdir. Biz bunu bütün dünyada dəniz, hava, quru, çay və ya qatarla və ya bu üsulların kombinasiyası ilə edirik. Biz xidmətlərimizi digərləri arasında neft və qaz, dib qazma, neft-kimya və dağ-mədən sənayesindən olan tanınmış qlobal müştərilərə təqdim edirik. Təcrübəli layihə komandamız böyük ölçülü və ya ağır yüklərinizi qayıq, təyyarə, avtomobil yolu, çay və ya dəmir yolu ilə göndərə və idarə edə bilər. Onlar kompleks layihə hərəkətlərinizin planlaşdırılacağını və bütün müddət ərzində izlənilməsini təmin edirlər."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mürəkkəb logistik əməliyyatları ən böyük diqqət və dəqiqliklə idarə edirik. Bizim nəqliyyatımıza qarşı qoyduğumuz tələblər çox yüksəkdir. Biz beynəlxalq logistika sahəsində mütəxəssislərik və kompleks layihə yükləriniz üçün fərdi həllər təklif edirik."])}
    },
    "why_us": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niyə Biz?"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məqsədimiz müştərilərin ehtiyac və maraqlarına diqqət yetirən yüksək təcrübəli, motivasiyalı işçi heyəti tərəfindən xidmət edilən Qlobal Bazar məkanına lokallaşdırılmış, logistik niş həllər təqdim etməkdir. Biz müştərilərimizə ən yaxşı xidmət keyfiyyətini təqdim etmək öhdəliyi götürürük. İşçilərimiz güclü və təcrübəli rəhbərlik tərəfindən asanlaşdırılır və dəstəklənir. Bu, bizə unikal sahibkarlıq baxışı və maliyyə dəstəyi verən sahiblər tərəfindən tamamlanır. NAKS TRADING artıq bir neçə müəssisəyə öz məhsullarını Azərbaycan və Çinə səmərəli idxal və ixrac etməkdə köməklik göstərmişdir. Logistika və daşıma sahəsində yüksək keyfiyyətli, etibarlı və fərdiləşdirilmiş xidmətlər göstərən menecerlər."])}
    }
  },
  "supply_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TƏCHİZAT ZƏNCİRİNİN OPTİMALLAŞDIRILMASI"])},
    "1_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alqı-satqı və ya satınalma məsələlərinə gəldikdə, təchizat zəncirinin optimallaşdırılması haradan başlayır?"])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sorğuda iştirak edən şirkətlərin 79%-i tədarük zəncirinin optimallaşdırılması üsullarından istifadənin gəliri əhəmiyyətli dərəcədə yaxşılaşdırdığını bildirir;"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["təchizat zəncirinin optimallaşdırılması təchizat zəncirinizi ən yüksək performansda işləmək üçün tənzimləmək prosesidir;"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cavab verən təchizat zənciri yaratmaq üçün çoxkanallı yanaşmadan istifadə edin;"])}
      },
      "paragraphs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Təchizat zəncirləri e-ticarət biznesinizin can damarıdır. İstehsalçı, distribyutor və ya pərakəndə satıcı olmağınızdan asılı olmayaraq, siz mürəkkəb logistika şəbəkəsinin bir hissəsisiniz."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Təchizat zəncirinin optimallaşdırılması təchizat zəncirinizi ən yaxşı şəkildə işləmək üçün təkmilləşdirmə prosesidir. Bu, əməliyyatlarınızı, inventarlarınızı, satınalmalarınızı və paylamalarınızı daha yaxşı uyğunlaşdırmaq yollarını tapmaq deməkdir."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaxşı olarsa, təchizat zəncirinizi optimallaşdırmaq biznesiniz üçün rəqabət üstünlüyü yarada bilər."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əgər şirkətinizdə satınalma prosesi deyil, satınalma prosesi varsa, bu, təşkilatda satınalmanın nisbi əhəmiyyətini əks etdirir və bu, işçilərə, potensial işə götürənlərə, təchizatçılara və satınalma yetkinlik səviyyənizlə bağlı sənayenin təəssüratına təsir göstərir."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alış, istədiyinizi ehtiyac duyduğunuz zaman əldə etmək prosesidir. Satınalma bütün təchizat zəncirinin ən yaxşı şəkildə yerinə yetirə bilməsi üçün tərəfdaşlarla uzunmüddətli, qarşılıqlı faydalı münasibətlərin formalaşdırılmasından ibarətdir; hər şey şirkətin dəyərini artırmaqdan ibarətdir."])}
      }
    },
    "2_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS TRADING təchizat zəncirinin optimallaşdırılması həlli"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz sizin xüsusi biznes ehtiyaclarınıza və bacarıqlarınıza uyğunlaşdırılmış şəkildə satınalma prosesinizi sıfırdan qura bilərik. Bu prosedur uzunmüddətli məqsədlər və mənfəət üçün yol açacaq, eləcə də mövcud təchizat zəncirinizi optimallaşdıracaq."])}
    },
    "3_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ni̇yə  Bi̇z?"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS TRADING geniş satınalma təcrübəsinə, eləcə də qeyri-standart tələblər üçün sifarişli biznes həlləri təqdim etmək qabiliyyətinə malikdir."])},
      "list": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAM SATINALMA XİDMƏTİMİZƏ DAXİLDİR:"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dünyanın ən yaxşı e-ticarət platformaları ilə inteqrasiya."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ehtiyaclarınızı tam şəkildə yerinə yetirmək üçün ehtiyatınızın tam idarə edilməsi və idarə olunması ilə yanaşı, tam satınalma xidməti təklif edirik. Satınalma kanallarımız illik tələblərə, hədəf layihələrə və ya hətta sonuncu dəfə satınalmaya əsaslanan zəruri komponentlərin uzunmüddətli mövcudluğunu təmin edir."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşağı qiymətli istehsala imkan yaratmağı hədəfləyən biz komponentləri mümkün olan ən aşağı qiymətə almağa qərarlıyıq. Bizim satınalma xidmətimiz hər bir müvafiq komponenti və təchizatçı seçimini yoxlayacaq və hərtərəfli hazırlanmış BOM əldə edəcək. Qlobal təchizat tərəfdaşlarımızın və yaxşı qurulmuş, nüfuzlu distribyutorlarımızın, o cümlədən ofşor tərəfdaşlığımızın dəstəyi ilə xərcləri azaltmaq üçün hissələrinizi ala bilərik."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əks logistika xidməti. NAKS TRADING müştərilərimizə qlobal anbarlar şəbəkəsi vasitəsilə qüsurlu məhsulları geri qaytarmaq imkanı verməyə hazırdır. Biz sadə qaytarma siyasəti və tez dəyişdirmə təmin edirik."])}
      }
    },
    "benefits": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satınalma xidmətləri üçün NAKS TRADING faydaları:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çin istehsalçılarının QC agentləri tərəfindən istehsalın keyfiyyətinə əlavə nəzarət"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çində istehsalla bağlı OEM/ODM yardımı"])}
    }
  },
  "trade_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TİCARƏT TƏŞVİQİNİN OPTİMALLAŞDIRILMASI"])},
    "1_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dəyər"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sərgilərdə iştirak etmək sizə müəyyən vaxtda bir məkanda çoxlu sayda dəyərli potensial müştərilərlə qarşılaşmağa kömək edir. Siz nümayiş etdirdiyiniz məhsul üçün uyğun sərgini seçməklə və etdiyiniz hər şeyi diqqətlə təşkil etməklə əlaqə və satış şəbəkənizi genişləndirmək üçün zəmin yaradacaqsınız."])},
      "paragraphs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əgər şirkət böyük platformada görünürsə, ən böyük şoularda iştirak onun nüfuzunu artırmağa kömək edir. Sərgilər məhsul haqqında dərhal rəy əldə etmək üçün əla yoldur. Əgər siz orijinal məhsulun hansı xüsusiyyətlərə malik olacağına əmin deyilsinizsə və ya onu hələ kütləvi istehsal etməmisinizsə və potensial alıcıların bunu qiymətləndirib-təqdir etmədiyini görmək istəyirsinizsə, sərgi onu yoxlamaq üçün əla yerdir."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siz əməkdaşlıq edə biləcəyiniz sərgilərdə satış nümayəndələri, təsisçilər, investorlar və məruzəçilər ilə tanış ola bilərsiniz."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siz sərgidə rəqiblərin öz brendlərini necə yerləşdirdiklərini, eksponatlarını tərtib etmək üçün hansı rənglərdən istifadə etdiklərini, nümayəndələrinin necə göründüyünü və potensial alıcıları necə şirnikləndirdiklərini müşahidə edə bilərsiniz."])}
      }
    },
    "2_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çağırış"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sərgiyə hazırlaşmaq çox vaxt və zəhmət tələb edir. Əgər sərgi başqa ölkədə keçirilirsə, siz vizaların rəsmiləşdirilməsini təşkil etməli, qalmağa yer tapmalı, iştirak edəcək heyəti seçməli, təqdimat, çıxış, stend dizaynı, işçi geyimləri, vizit kartları, broşuralar və bukletlər hazırlamalısınız. Bütün bunları etməyə başlayanda bunun həqiqətən vacib olub olmadığını soruşursunuz."])},
      "paragraphs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müxtəlif tədbirlərdə iştirak etmək məntiqə uyğundur. Alıcılar, satıcılar və investorlar bu günün rəqəmsal dünyasında qarşılıqlı əlaqədə olmaq, məlumat almaq, mühakimə yürütmək və müqavilələr bağlamaq istəyirlər. Bunun ən yaxşı yolu sərgi və tədbirlərdən keçir."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu, sərgilərə gələnlərin həmişə əhval-ruhiyyədə olduğu bir şeydir. Onlar müəyyən bir tədbirdə təcrübə qazanmaq və fikirləri nəzərdən keçirmək niyyəti ilə iştirak edirlər. İnvestorlar investisiya imkanlarını axtarmaq üçün gələ bilərlər. Nəticə etibarı ilə sərgilər çox mühüm hadisələr kimi qiymətləndirilir. Menecerlər adi satış dövrü ərzində əlaqə keyfiyyətlərini inkişaf etdirməyə çox vaxt sərf edirlər. Ancaq, sərgilərdə bu mərhələlər buraxılır, çünki tamaşaçılar maraqlanır. Bu şəkildə tamaşaçıların diqqətini çəkdiyinizə əmin ola bilərsiniz."])}
      }
    },
    "3_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Həll yolu"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir çox biznes hələ də maliyyə çatışmazlığı səbəbindən ekspo və ya digər tədbirdə iştirak edə bilmir. Onlar təkcə giriş haqlarını deyil, həm də səyahət, viza, yaşayış, stend dizaynı, bütün paylama materiallarının çapı və menecerlərin və ya korporativ nümayəndələrin əmək haqqı kimi bütün xərcləri ödəməlidirlər. NAKS TRADING müxtəlif müəssisələrə və yeni başlayanlara ticarət sərgilərində iştirak etməkdə kömək etmək üçün xüsusi şərtlər təqdim etmək qərarına gəlib. Biz ekspozisiya stendlərinin xərclərini bölüşdürəcəyimizi və reklam materiallarının yaradılmasında kömək etmək üçün promouterləri, məruzəçiləri, marketoloqları və dizaynerləri təmin edəcəyimizi vəd edirik. Eyni zamanda, NAKS TRADING hərtərəfli iştirak göstəriciləri və məsləhətlər verir."])}
    }
  },
  "logistics_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ƏLAVƏ DƏYƏR VERƏN LOGİSTİKA XİDMƏTLƏRİ"])},
    "1_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribyutor mərkəzlərinin təşkili"])},
      "paragraphs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daxili logistika təşkilatını və infrastrukturunu inkişaf etdirmək üçün tələb olunan kapital qoyuluşunu tapmaq çox vaxt problem yaradır. Bu funksiyanın daha çoxunu dəyişən xərc kimi idarə etmək daha məqsədəuyğun ola bilər."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistika əməliyyatlarının və əlaqəli texnologiyanın artan mürəkkəbliyini mənimsəmək, mütləq tək istehsalçının və ya pərakəndə satış şəbəkəsinin missiyası olmaya bilər. Bir logistika mütəxəssisi ilə işləməyi düşünmənin vaxtı gəldi."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depolama və paylamada əlavə dəyərli xidmətlər normal əməliyyatlardan daha irəli gedir və sizi rəqiblərinizdən fərqləndirə bilər. Bu fəaliyyət növləri arasında çarpaz yerləşdirmə, köçürmə, paletləmə, komplektləşdirmə və geri qaytarma emalını göstərmək olar."])}
      }
    },
    "2_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS TRADING ilə logistika üstünlükləri"])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məhsulların son müştərilərə daşınması və satışı xərclərinin azaldılması."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xüsusilə qeyri-standart sifarişlər və ya çatdırılma şərtləri (parça tədarükü, vaxtında çatdırılmanın təşkili) üçün çevik xammal portfelinin yaradılması bacarığı."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nəqliyyat və anbar təsərrüfatı üçün infrastruktur icarəyə verilir."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məhsul və mal logistikası, anbar və klirinq əməliyyatları üzərində nəzarətin artırılması."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipping. Biz sosial mediadan və onlayn mağazalardan öz adımızdan müəyyən bir satıcıdan və ya bir sıra tacirlərdən olan əşyaları bazara çıxarmaq üçün istifadə edə bilərik."])}
      }
    },
    "3_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niyə NAKS TRADING?"])},
      "paragraphs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstehsal komponentlərini istehsal nöqtəsindən müştərinin olduğu yerə çatdırırıq, bütün sənədləşmə işləri, daşınma və gömrük rəsmiləşdirilməsinə diqqət yetiririk."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS TRADING xammal və malların qablaşdırılması, etiketlənməsi, saxlanması və daşınması ilə bağlı bütün aspektləri idarə edir və biznes rəhbərliyinin diqqətini məhsul üzərində cəmləşdirməyə imkan verir."])}
      }
    }
  },
  "products_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MƏHSULLAR"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha çoxunu əldə edin"])},
    "links": {
      "consumer_electronics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məişət elektronikası"])},
      "alternative_energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avtomobil hissələri"])},
      "health_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sağlamlıq və Rifah Məhsulları"])},
      "industrial_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sənaye avadanlıqları"])},
      "souvenir_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suvenir məhsulları"])}
    },
    "consumer_electronics": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məişət elektronikası"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz tələbkar bazarın tələblərini yerinə yetirmək üçün mümkün olan ən yaxşı xidmətə və rəqabətli qiymətlərə diqqət yetiririk. Təklif edə biləcəyimiz:"])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məlumat saxlama (SSD və HDD)"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qrafik və mayninq avadanlıqları (PC üçün qrafik kartları)"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mərkəzi prosessor bölmələri və IC"])}
      }
    },
    "alternative_energy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avtomobil hissələri"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz 10 ildən artıqdır ki, orijinal avtomobil hissələri biznesində fəaliyyət göstərən, köklü ixracyönümlü bir şirkətik."])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Təcrübəmiz sayəsində biz müştərilərimizə mexanika, kuzov və aksessuarlar üçün çoxlu sayda orijinal ehtiyat hissələrini yüksək rəqabətli qiymətlərlə təmin edə bilərik."])}
    },
    "health_products": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tibbi məhsullar və ləvazimatlar"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizim tibbi məhsullarımız keyfiyyətə daimi tələbat və qənaətə artan tələb arasında körpü yaradır. Brend portfelimiz klinisistlərin üstünlük verdiyi, qənaətcil məhsulların və aşağı klinik differensiasiyaya malik həkimlərin üstünlük verdiyi məhsulların əhatəli təklifidir və provayderlərə yüksək keyfiyyətli xidmət göstərərkən nəticəni yaxşılaşdırmağa kömək edir. Kəskin kanaldan evə qədər işlədiyimiz üçün baxımı irəli aparan məhsulun səmərəliliyini taparaq, öndə qalmaq üçün unikal mövqeyə sahibik."])}
    },
    "industrial_equipment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sənaye avadanlıqları"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz bir çox Kimya və Neftayırma Kompleksləri, Sənaye və Ticarət Podratçıları, Bələdiyyələr, Kommunal və Enerji Sənayesi, Su və Tullantı Suları, Qida və Əczaçılıq İstehsalçıları, Selüloz və Kağız Sənayeləri və Elektrik, Alətlər, Material emalı Məhsulları, Yanma və HVAC Məhsulları ilə əlaqəli Mühəndislik Şirkətlərindən ibarət unikal bazara xidmət edirik."])}
    },
    "souvenir_products": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suvenir məhsulları"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz yeniliklər, oyuncaqlar, oyunlar, zinət əşyaları, günəş eynəkləri, şişmə əşyalar, parıltılı əşyalar, kostyum aksesuarları və ziyafət məhsullarının geniş seçimini təklif edirik. Biz həmçinin sifarişlə çap olunmuş suvenirlərin və promosyon məhsullarının geniş çeşidini təklif edirik:"])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xüsusi hazırlanmış qələmlər"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xüsusi hazırlanmış təqvimlər"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xüsusi hazırlanmış flash sürücülər"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mağazalar və yanacaqdoldurma məntəqələri üçün stendlər"])}
      }
    }
  },
  "contacts_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktları"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hər hansı bir sualınız olarsa, bizə müraciət etməkdən çəkinməyin."])},
    "form": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göndərin"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Düyməni klikləməklə siz istifadəçi müqaviləsi və"])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məxfilik Siyasəti"])},
      "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ilə razılaşırsınız"])},
      "placeholders": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirkət"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-poçt"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
        "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçimlərdən birini seçin"])},
        "options": {
          "consumer_electronics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məişət elektronikası"])},
          "alternative_energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avtomobil hissələri"])},
          "health_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sağlamlıq və Rifah Məhsulları"])},
          "industrial_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sənaye avadanlıqları"])},
          "souvenir_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suvenir məhsulları"])}
        },
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj"])}
      }
    }
  },
  "privacy_page": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MƏXFİLİK SİYASƏTİ"])},
    "paragraphs": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naks-trading.biz saytından daxil oluna bilən Naks-trading.biz saytında bizim əsas prioritetlərimizdən biri ziyarətçilərimizin məxfiliyidir. Bu Məxfilik Siyasəti sənədi Naks-trading.biz tərəfindən toplanan və qeydə alınan məlumat növlərini və ondan necə istifadə etdiyimizi ehtiva edir."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əlavə suallarınız varsa və ya Məxfilik Siyasətimiz haqqında əlavə məlumat tələb edirsinizsə, bizimlə əlaqə saxlamaqdan çəkinməyin. Bu Məxfilik Siyasəti yalnız onlayn fəaliyyətlərimizə aiddir və Naks-trading.biz-də paylaşdıqları və/yaxud topladıqları məlumatlarla bağlı vebsaytımıza gələnlər üçün etibarlıdır."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu siyasət oflayn və ya bu vebsaytdan başqa kanallar vasitəsilə toplanmış hər hansı məlumat üçün tətbiq edilmir."])}
    },
    "1_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAZILIQ"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veb saytımızdan istifadə etməklə, siz Məxfilik Siyasətimizə razılıq verir və onun şərtləri ilə razılaşırsınız."])}
    },
    "2_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOPLADIĞIMIZ MƏLUMATLAR"])},
      "paragraphs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sizdən təqdim etməyiniz xahiş olunan şəxsi məlumat və onu təqdim etməyin səbəbləri sizdən şəxsi məlumatlarınızı təqdim etməyinizi xahiş etdiyimiz anda sizə aydın olacaq."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizimlə birbaşa əlaqə saxlasanız, adınız, e-poçt ünvanınız, telefon nömrəniz, bizə göndərə biləcəyiniz mesajın məzmunu və/yaxud əlavələr və təqdim etməyi seçə biləcəyiniz hər hansı digər məlumat kimi sizin haqqınızda əlavə məlumat ala bilərik."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siz Hesab üçün qeydiyyatdan keçdiyiniz zaman ad, şirkət adı, ünvan, e-poçt ünvanı və telefon nömrəsi kimi elementlər də daxil olmaqla, əlaqə məlumatlarınızı istəyə bilərik."])}
      }
    },
    "3_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SİZİN MƏLUMATINIZDAN NECƏ İSTİFADƏ EDİRİK"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz topladığımız məlumatları müxtəlif yollarla istifadə edirik, o cümlədən:"])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veb saytımızı təmin edirik, idarə edirik və saxlayırıq."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veb saytımızı təkmilləşdirir, fərdiləşdirir və genişləndiririk."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veb saytımızdan necə istifadə etdiyinizi başa düşürük və təhlil edirik."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz yeni məhsullar, xidmətlər, xüsusiyyətlər və funksionallıq inkişaf etdiririk."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz sizinlə birbaşa və ya tərəfdaşlarımızdan biri vasitəsilə, o cümlədən müştəri xidməti, vebsaytla bağlı yeniləmələri və digər məlumatları sizə təqdim etmək, marketinq və tanıtım məqsədləri üçün əlaqə saxlayırıq."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz sizə e-poçt göndəririk."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz fırıldaqçılığı tapırıq və qarşısını alırıqFind and prevent fraud."])}
      }
    },
    "4_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GİRİŞ FAYLLARI"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naks-trading.biz log fayllarından istifadə etmək üçün standart prosedura əməl edir. Bu fayllar veb-saytları ziyarət etdikdə ziyarətçiləri qeyd edir. Bütün hosting şirkətləri bunu və hosting xidmətlərinin analitikasının bir hissəsini edir. Jurnal faylları tərəfindən toplanan məlumatlara internet protokolu (IP) ünvanları, brauzer növü, İnternet Xidməti Provayderi (ISP), tarix və vaxt möhürü, istinad/çıxış səhifələri və bəlkə də kliklərin sayı daxildir. Bunlar şəxsiyyəti müəyyənləşdirə bilən hər hansı məlumatla əlaqəli deyil. Məlumatın məqsədi trendləri təhlil etmək, saytı idarə etmək, istifadəçilərin vebsaytdakı hərəkətini izləmək və demoqrafik məlumat toplamaqdır."])}
    },
    "5_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KUKİLƏR VƏ VEB MAYAKLARI"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hər hansı digər vebsayt kimi, Naks-trading.biz də “kukilərdən” istifadə edir. Bu kukilər ziyarətçilərin seçimləri və ziyarətçinin daxil olduğu və ya ziyarət etdiyi vebsaytdakı səhifələr daxil olmaqla məlumatları saxlamaq üçün istifadə olunur. Məlumat ziyarətçilərin brauzer tipinə və/və ya digər məlumatlara əsaslanaraq veb səhifəmizin məzmununu fərdiləşdirməklə istifadəçilərin təcrübəsini optimallaşdırmaq üçün istifadə olunur."])}
    },
    "6_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REKLAM TƏRƏFDAŞLARININ MƏXFİLİK SİYASƏTİ"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naks-trading.biz-in reklam tərəfdaşlarının hər biri üçün Məxfilik Siyasətini tapmaq üçün bu siyahıya müraciət edə bilərsiniz. Üçüncü tərəf reklam serverləri və ya reklam şəbəkələri birbaşa istifadəçilərin brauzerinə göndərilən wtrading.org saytında görünən müvafiq reklamlarında və keçidlərində istifadə olunan kukilər, JavaScript və ya Veb Mayaklar kimi texnologiyalardan istifadə edir. Bu baş verdikdə onlar sizin IP ünvanınızı avtomatik olaraq alırlar. Bu texnologiyalar onların reklam kampaniyalarının effektivliyini ölçmək və/yaxud ziyarət etdiyiniz vebsaytlarda gördüyünüz reklam məzmununu fərdiləşdirmək üçün istifadə olunur. Nəzərə alın ki, Naks-trading.biz üçüncü tərəf reklamçıları tərəfindən istifadə edilən bu kukilərə giriş və ya onlara nəzarət edə bilməz."])}
    },
    "7_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÜÇÜNCÜ TƏRƏFİN MƏXFİLİK SİYASƏTİ"])},
      "paragraphs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naks-trading.biz Məxfilik Siyasəti digər reklamçılara və ya vebsaytlara şamil edilmir. Beləliklə, biz sizə daha ətraflı məlumat üçün bu üçüncü tərəf reklam serverlərinin müvafiq Məxfilik Siyasətlərinə müraciət etməyinizi tövsiyə edirik. Buraya onların təcrübələri və müəyyən seçimlərdən necə imtina etmək barədə təlimatlar daxil ola bilər."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siz fərdi brauzer seçimləriniz vasitəsilə kukiləri söndürməyi seçə bilərsiniz. Xüsusi veb-brauzerlərlə kukilərin idarə edilməsi haqqında daha ətraflı məlumatı brauzerlərin müvafiq veb-saytlarında əldə etmək olar."])}
      }
    },
    "8_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCPA MƏXFİLİK HÜQUQLARI (ŞƏXSİ MƏLUMATIMI SATMAYIN)"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCPA çərçivəsində, digər hüquqlarla yanaşı, Kaliforniya istehlakçıları aşağıdakı hüquqlara malikdir:"])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstehlakçının şəxsi məlumatlarını toplayan biznesin istehlakçılar haqqında topladığı fərdi məlumatların kateqoriyalarını və xüsusi şəxsi hissələrinin açıqlamasını tələb etmək."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biznesin istehlakçı haqqında topladığı şəxsi məlumatların silinməsini tələb etmək."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstehlakçının şəxsi məlumatlarını satan bir müəssisədən istehlakçının şəxsi məlumatlarını satmamasını tələb etmək."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əgər müraciət etsəniz, sizə cavab vermək üçün bir ay vaxtımız var. Bu hüquqlardan hər hansı birini istifadə etmək istəyirsinizsə, bizimlə əlaqə saxlayın."])}
      }
    },
    "9_section": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR MƏLUMATLARIN QORUNMASI HÜQUQLARI"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bütün məlumatların qorunması hüquqlarınızdan tam xəbərdar olduğunuza əmin olmaq istərdik. Hər bir istifadəçi aşağıdakı hüquqlara malikdir:"])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş hüququ – Sizin şəxsi məlumatlarınızın surətlərini tələb etmək hüququnuz var. Bu xidmət üçün sizdən kiçik bir ödəniş tələb edə bilərik."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzəliş etmək hüququ – Sizin qeyri-dəqiq hesab etdiyiniz hər hansı məlumatı düzəltməmizi tələb etmək hüququnuz var. Siz həmçinin natamam hesab etdiyiniz məlumatı doldurmağımızı tələb etmək hüququnuz var."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmək hüququ - Siz müəyyən şərtlər daxilində şəxsi məlumatlarınızı silməyimizi tələb etmək hüququnuz var."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emalın məhdudlaşdırılması hüququ - Siz müəyyən şərtlər daxilində şəxsi məlumatlarınızın işlənməsini məhdudlaşdırmağımızı tələb etmək hüququnuz var."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emala etiraz etmək hüququ - Müəyyən şərtlər daxilində şəxsi məlumatlarınızı emal etməyimizə etiraz etmək hüququnuz var."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Məlumatların daşınması hüququ – Sizin topladığımız məlumatları müəyyən şərtlər daxilində başqa təşkilata və ya birbaşa sizə ötürməyimizi tələb etmək hüququnuz var."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Əgər müraciət etsəniz, sizə cavab vermək üçün bir ay vaxtımız var. Bu hüquqlardan hər hansı birini istifadə etmək istəyirsinizsə, bizimlə əlaqə saxlayın."])}
      }
    }
  },
  "footer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xidmət haqqında ətraflı məlumat əldə edin"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAKS TRADING sizə lazım olan hərtərəfli xidməti təqdim edəcək."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulsuz konsultasiya sifariş edin"])}
  }
}