<template>
  <PrivacyPolicyPage />
</template>

<script>
import PrivacyPolicyPage from "../components/pages/PrivacyPolicyPage.vue";

export default {
  components: {
    PrivacyPolicyPage,
  },
};
</script>
