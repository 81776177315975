<template>
  <TradePromotionPage />
</template>

<script>
import TradePromotionPage from "../components/pages/TradePromotionPage.vue";

export default {
  components: {
    TradePromotionPage,
  },
};
</script>
