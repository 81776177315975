<template>
  <ImportPage />
</template>

<script>
import ImportPage from "../components/pages/ImportPage.vue";

export default {
  components: {
    ImportPage,
  },
};
</script>
